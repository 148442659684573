
import {Vue, Component, Inject, Emit} from 'vue-property-decorator'
import AppLayoutDefault from '@/components/layout/AppLayoutDefault'
import DataTable from '@/components/entity/DataTable'
import type {TDataTableConstruction, TSortRecord} from '@/components/entity/DataTable/DataTable.vue'
import SwitchInput from '@/components/ui/SwitchInput'
import Checkbox from '@/components/ui/Checkbox'
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import Loader from '@/components/ui/Loader'
import Pagination from '@/components/entity/Pagination'
import DataTableMenu from '@/components/entity/DataTableMenu'
import {TAPIEmployee, TAPIEmployeeStatus} from '@/types/api.type'
import Modal from '@/components/entity/Modal'
import ModalLimit from '@/components/entity/ModalLimit'
import {TModalSubmitData} from '@/components/entity/ModalLimit/ModalLimit.vue'
import {ApiService} from '@/services/api.service'
import ErrorNotifierService from '@/services/errorNotifier.service'
import {RobotWS} from '@/services/robotws.service'
import {RobotWSMessage} from '@/types/robotws.type'

@Component({
  components: {
    AppLayoutDefault,
    DataTable,
    SwitchInput,
    Checkbox,
    'TextInput': Input.Text,
    Button,
    Loader,
    Pagination,
    DataTableMenu,
    Modal,
    ModalLimit
  }
})
export default class AppFoodLimit extends Vue {
  @Inject()
  readonly $api!: ApiService

  @Inject()
  readonly $error!: ErrorNotifierService

  @Inject()
  readonly $ws!: RobotWS

  LIMIT_MAX = 999999
  EMPLOYEES_LINK = window.location.origin + '/employees'

  loading: boolean = true

  page = 1
  pageSize = 15
  pageCount = 1

  sortRules: TSortRecord = {
    fullName: {
      ascending: (a: TAPIEmployee, b: TAPIEmployee) => a.fullName.localeCompare(b.fullName),
      descending: (a: TAPIEmployee, b: TAPIEmployee) => b.fullName.localeCompare(a.fullName)
    },
    phone: {
      ascending: (a: TAPIEmployee, b: TAPIEmployee) => a.phone.localeCompare(b.phone),
      descending: (a: TAPIEmployee, b: TAPIEmployee) => b.phone.localeCompare(a.phone)
    },
    status: {
      ascending: (a: TAPIEmployee, b: TAPIEmployee) => Number(a.status === 'enabled'),
      descending: (a: TAPIEmployee, b: TAPIEmployee) => Number(b.status === 'enabled')
    },
    limit: {
      ascending: (a: TAPIEmployee, b: TAPIEmployee) => Number(a.limit > b.limit),
      descending: (a: TAPIEmployee, b: TAPIEmployee) => Number(a.limit < b.limit)
    }
  }

  search = ''

  tableData: TAPIEmployee[] = []
  tableConstruction: TDataTableConstruction[] = [
    {name: 'fullName', title: 'Сотрудник', sortable: true, basis: '30%'},
    {name: 'phone', title: 'Телефон', sortable: true, basis: '21%'},
    {name: 'status', title: 'Статус услуги', sortable: true, basis: '21%'},
    {name: 'limit', title: 'Лимит, ₽/мес', sortable: true, basis: '21%'}
  ]

  statusText = {
    enabled: 'Включена',
    disabled: 'Отключена',
    processEnabled: 'В процессе подключения',
    processDisabled: 'В процессе отключения'
  }

  limitModalShown: boolean = false
  selectedEmployeeIds: string[] = []

  get isPaginationVisible () {
    return this.pageCount > 1
  }

  @Emit('redirect')
  emitRedirect () {}

  async created () {
    try {
      const response = await this.$api.fetchEmployeesList(this.page, this.pageSize)
      const {data} = response
      const {page, pageSize, pagesCount} = data.meta.pagination

      this.page = page
      this.pageSize = pageSize
      this.pageCount = pagesCount
      this.tableData = data.data

      const externalEmployeeIds = data.data.map(item => item.externalEmployeeID)

      await this.$ws.connect(externalEmployeeIds)
      this.$ws.socket.onmessage = this.onWSMessage.bind(this)
      this.$ws.socket.onerror = this.onWSError
    } catch (_: unknown) {
      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось получить список сотрудников, для получения помощи свяжитесь с менеджером'
      })
    } finally {
      this.loading = false
    }
  }

  async onPaginationInput (_page: number) {
    try {
      const response = await this.$api.fetchEmployeesList(_page, this.pageSize)
      const {data} = response
      const {page} = data.meta.pagination

      this.page = page
      this.tableData = data.data

      this.$ws.close()
      const externalEmployeeIds = data.data.map(item => item.externalEmployeeID)
      await this.$ws.connect(externalEmployeeIds)
      this.$ws.socket.onmessage = this.onWSMessage.bind(this)
      this.$ws.socket.onerror = this.onWSError
    } catch (error) {
      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось получить список сотрудников, для получения помощи свяжитесь с менеджером'
      })
    }
  }

  async onChangeStatusAll (selectedIds: string[], status: TAPIEmployeeStatus) {
    const reservedRowData = [...this.tableData]

    try {
      const updatedTableData = reservedRowData.map(item => {
        if (selectedIds.includes(item.employeeID)) {
          item.status = status
          item.unlimited = status === 'processEnabled'
          item.limit = status === 'processDisabled' ? 0 : item.limit
        }

        return item
      })
      const updatedTableDataDto = updatedTableData.filter(item => selectedIds.includes(item.employeeID))

      this.tableData = updatedTableData

      if (updatedTableDataDto.length) {
        await this.$api.patchEmployeeSettings(updatedTableDataDto)
      } else {
        this.$error.push({
          title: 'Ошибка клиента',
          message: 'Не удалось обработать список сотрудников, для получения помощи свяжитесь в менеджером'
        })
      }
    } catch (error) {
      this.tableData = reservedRowData

      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
      })
    }
  }

  async onDataTableChangeData (id: string, data: {field: keyof TAPIEmployee, value: never}[]) {
    const rowIndex = this.tableData.findIndex(item => item.employeeID === id)
    const row = this.tableData[rowIndex]

    if (row) {
      // Резервная копия данных для восстановления при ошибке
      const reservedRowData = {...row}

      data.forEach(item => {
        row[item.field] = item.value
      })
      row.status = 'processEnabled'
      this.$set(this.tableData, rowIndex, row)

      // Визуально поменяли данные, делаем запрос
      try {
        await this.$api.patchEmployeeSettings([row])
      } catch (error) {
        this.$error.push({
          title: 'Ошибка сервера',
          message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
        })

        // Ошибка, восстанавливаем данные
        this.$set(this.tableData, rowIndex, reservedRowData)
      }
    }
  }

  async onDataTableStatusChanged (value: boolean, employeeID: string) {
    const rowIndex = this.tableData.findIndex(item => item.employeeID === employeeID)
    const row = {...this.tableData[rowIndex]}

    if (row) {
      const reservedRowData = {...row}

      row.status = value ? 'processEnabled' : 'processDisabled'
      row.unlimited = row.status === 'processEnabled'
      row.limit = row.status === 'processDisabled' ? 0 : row.limit

      this.$set(this.tableData, rowIndex, row)

      try {
        await this.$api.patchEmployeeSettings([row])
      } catch (error) {
        this.$error.push({
          title: 'Ошибка сервера',
          message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
        })

        // Ошибка, восстанавливаем данные
        this.$set(this.tableData, rowIndex, reservedRowData)
      }
    }
  }

  onWSMessage (event: MessageEvent<string>) {
    const data = JSON.parse(event.data) as RobotWSMessage

    if (data.errorMessage) {
      return this.$error.push({
        title: 'Ошибка обновления статуса',
        message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
      })
    }

    const rowIndex = this.tableData.findIndex(item => item.externalEmployeeID === data.externalEmployeeID)
    const row = this.tableData[rowIndex]

    if (row) {
      row.status = data.status
      this.$set(this.tableData, rowIndex, row)
    }
  }

  onWSError () {
    this.$error.push({
      title: 'Ошибка подключения к сервису обновления данных',
      message: 'Возможна не корректность в информации о статусе сотрудников, , для получения помощи свяжитесь с менеджером'
    })
  }

  onLimitControl (selectedIds: string[]) {
    this.selectedEmployeeIds = selectedIds
    this.limitModalShown = true
  }

  onLimitFormSubmit (data: TModalSubmitData) {
    const {status, limit, unlimited} = data.data
    const {employeeIds} = data

    this.tableData = this.tableData.map(item => {
      if (employeeIds.includes(item.employeeID)) {
        item.status = status
        item.limit = limit
        item.unlimited = unlimited
      }

      return item
    })
  }

  onSearchUpdate (value: string) {
    this.search = value
  }
}
