// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg width=%2710%27 height=%278%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M3.367 7.518a.552.552 0 0 1-.422-.17L.162 4.563a.467.467 0 0 1-.14-.356.503.503 0 0 1 .852-.357l2.493 2.493L9.126.584A.484.484 0 0 1 9.482.44c.141 0 .26.048.356.145a.484.484 0 0 1 .145.356c0 .141-.048.26-.145.356l-6.05 6.05a.552.552 0 0 1-.421.17Z%27 fill=%27%23fff%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c-checkbox{display:flex;align-items:center;cursor:pointer}.c-checkbox__body{width:24px;height:24px;flex:0 0 24px}.c-checkbox__body,.c-checkbox__box{display:flex;align-items:center;justify-content:center}.c-checkbox__box{width:16px;height:16px;border:1px solid #86919c;border-radius:2px;position:relative}.c-checkbox__box_active,.c-checkbox__box_indeterminate{background-color:#00b0cc;border:none}.c-checkbox__box_active:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.c-checkbox__box_indeterminate:after{content:\"\";width:9px;height:1px;background-color:#fff;border-radius:50%}.c-checkbox__label{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.14px;color:#21272b}.c-checkbox_disabled{cursor:not-allowed}.c-checkbox_disabled .c-checkbox__box{border-color:#ebedf1}.c-checkbox_disabled .c-checkbox__box_active,.c-checkbox_disabled .c-checkbox__box_indeterminate{background-color:#ebedf1}.c-checkbox_disabled .c-checkbox__label{color:#86919c}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
