
import {Vue, Component, VModel, Prop} from 'vue-property-decorator'

@Component
export default class Checkbox extends Vue {
  @VModel({type: Boolean}) state!: boolean

  @Prop(Boolean) readonly indeterminate?: boolean

  @Prop(Boolean) readonly clickDisable?:boolean

  @Prop(Boolean) readonly disabled?:boolean

  get classes () {
    return {
      'c-checkbox__box_active': this.state && !this.indeterminate,
      'c-checkbox__box_indeterminate': this.state && this.indeterminate
    }
  }

  onClick () {
    if (this.clickDisable || this.disabled) return

    this.state = !this.state
  }
}
