
import {Vue, Component, Prop, VModel, Emit} from 'vue-property-decorator'

@Component
export default class TextInput extends Vue {
  @VModel({type: [String, Number]})
  state!: string | number

  @Prop({type: String, default: ''})
  readonly size?: 's' | 'm' | 'l'

  @Prop({type: String, default: ''})
  readonly placeholder?: string

  @Prop({type: Number, default: null})
  readonly maxlength?: number | null

  @Prop({type: String, default: ''})
  readonly id?: string

  @Prop(Boolean)
  readonly center?: boolean

  @Prop(Boolean)
  readonly error?: boolean

  @Prop(Boolean)
  readonly disabled?: boolean

  @Prop(Boolean)
  readonly number?: boolean

  get options () {
    const options: Record<string, any> = {id: this.id}

    if (this.maxlength) {
      options.maxlength = this.maxlength
    }

    return options
  }

  get classes () {
    return [
      `c-text__size-${this.size}`,
      {'c-text_disabled': this.disabled}
    ]
  }

  onInput (e: Event) {
    if (this.number && !/^\d*\.?\d*$/.test((e.target as HTMLInputElement).value)) {
      (e.target as HTMLInputElement).value = ''
    }

    this.state = (e.target as HTMLInputElement).value
  }

  @Emit('change')
  emitChange (e: Event) {
    return (e.target as HTMLInputElement).value
  }
}
