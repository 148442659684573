
import {Vue, Component, Emit, Prop, VModel, Inject} from 'vue-property-decorator'
import Button from '@/components/ui/Button'
import ErrorNotifierService from '@/services/errorNotifier.service'

@Component({
  components: {
    Button
  }
})
export default class DataTableMenu extends Vue {
  @VModel({type: Boolean, default: false})
  state!: boolean

  @Prop(Number)
  readonly selectCount!: number

  @Prop({type: Array, default: () => []})
  readonly employeeIds: string[]

  @Inject()
  readonly $error!: ErrorNotifierService

  onDispatchFromContext (action: string) {
    const method = this[action as keyof this]

    if (method && typeof method === 'function') {
      method()
    } else {
      this.$error.push({
        title: 'Ошибка клиента',
        message: 'Метод onDispatchFromContext не нашел указанный метод контекстного меню, для получения помощи свяжитесь с менеджером'
      })
    }
  }

  @Emit('select-all')
  onSelectAll () {}

  @Emit('remove-selection-all')
  onRemoveSelectionAll () {}

  @Emit('enable-all')
  onEnableAll () {}

  @Emit('disable-all')
  onDisableAll () {}

  @Emit('limit-control')
  onLimitControl () {}
}
