import {Emitter} from 'mitt'
import {GlobalEvents} from '@/types/mitt.types'
import {TErrorNotifierList} from '@/components/entity/ErrorNotifier/ErrorNotifier.vue'

export default class ErrorNotifierService {
  emitter: Emitter<GlobalEvents>

  constructor (emitter: Emitter<GlobalEvents>) {
    this.emitter = emitter
  }

  push (error: TErrorNotifierList) {
    this.emitter.emit('push-error', error)
  }
}
