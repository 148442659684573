
import {PropType} from 'vue'
import {Vue, Component, Emit, Prop} from 'vue-property-decorator'

@Component
export default class ErrorNotifierAlert extends Vue {
  @Prop({
    type: String as PropType<string>,
    default: ''
  })
  readonly title: string

  @Prop({
    type: String as PropType<string>,
    default: ''
  })
  readonly text: string

  @Emit('close')
  onCloseClick () {}
}
