import { render, staticRenderFns } from "./AppLayoutDefault.vue?vue&type=template&id=607ec5cb&"
import script from "./AppLayoutDefault.vue?vue&type=script&lang=ts&"
export * from "./AppLayoutDefault.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./style.scss?vue&type=style&index=0&prod&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports