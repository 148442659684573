
import {Vue, Component, Prop, VModel} from 'vue-property-decorator'
import Button from '@/components/ui/Button'

@Component({
  components: {
    Button
  }
})
export default class Modal extends Vue {
  @VModel({type: Boolean}) state!: false

  @Prop(String)
  readonly title: string

  get classes () {
    return {
      'c-modal_active': this.state
    }
  }

  onCloseClick () {
    this.onClose()
  }

  onClose () {
    this.state = false
  }
}
