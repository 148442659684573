
import {Vue, Component, Inject} from 'vue-property-decorator'
import {nanoid} from 'nanoid'
import ErrorNotifierAlert from './ErrorNotifierAlert'
import {GlobalEvents} from '@/types/mitt.types'
import {Emitter} from 'mitt'

export type TErrorNotifierList = {
  title: string
  message: string
}

type TUniqueNotifierData = TErrorNotifierList & { id: string }

@Component({
  components: {
    ErrorNotifierAlert
  }
})
export default class ErrorNotifier extends Vue {
  @Inject()
  readonly $emitter!: Emitter<GlobalEvents>

  errorList: TUniqueNotifierData[] = []

  mounted () {
    this.$emitter.on('push-error', this.push)
  }

  beforeDestroy () {
    this.$emitter.off('push-error', this.push)
  }

  push (err: TErrorNotifierList) {
    this.errorList.push({
      ...err,
      id: nanoid()
    })
  }

  clear (id: string) {
    this.errorList = this.errorList.filter(_err => _err.id !== id)
  }
}
