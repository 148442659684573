
import {Vue, Component, VModel} from 'vue-property-decorator'

export type TSortInputValue = 'ascending' | 'descending' | null

@Component
export default class SortInput extends Vue {
  @VModel({type: String}) state!: TSortInputValue

  get isAscending () {
    return this.state === 'ascending'
  }

  get isDescending () {
    return this.state === 'descending'
  }

  get isUnknown () {
    return !this.state
  }

  onClick () {
    if (this.isUnknown) {
      this.state = 'ascending'
    }

    if (this.isAscending) {
      this.state = 'descending'
    }

    if (this.isDescending) {
      this.state = null
    }
  }
}
