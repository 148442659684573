export class RobotWS {
  public socket: WebSocket

  constructor () {}

  close () {
    if (this.socket) {
      this.socket.close()
    }
  }

  async connect (externalEmployeeIDs: string[]) {
    this.socket = new WebSocket(process.env.VUE_APP_WS_BASE_URL)
    this.waitForConnection(
      () => {
        this.socket.send(JSON.stringify({
          externalEmployeeIDs: [
            ...externalEmployeeIDs
          ]
        }))
      },
      1000
    )
  }

  waitForConnection (callback: () => void | Promise<void>, interval: number) {
    if (this.socket?.readyState === 1) {
      callback()
    } else {
      const self = this

      setTimeout(function () {
        self.waitForConnection(callback, interval)
      }, interval)
    }
  }
}
